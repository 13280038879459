import { useEffect, useState } from 'react';
import './App.css';
import Finances from './components/Finances/Finances';
import { Routes, Route, Navigate } from 'react-router-dom';
import Main from './components/Main/Main';
import Register from './components/Register/Register';
import { useTelegram } from "./hooks/useTelegram"
import Admin from './components/Admin/Admin';
import AdminUser from './components/Admin/AdminUser';
import AlertsAdmin from './components/Admin/AlertsAdmin';

import AdminList from './components/Admin/AdminList';
import TradeAdminList from './components/Admin/TradeAdminList';

import DiscordList from './components/Admin/DiscordList';

import FinancesAdminList from './components/Admin/FinancesAdminList';
import HistoryAdminList from './components/Admin/HistoryAdminList';
import Instructions from './components/Instructions/Instructions';
import TradeRegister from './components/Trade/TradeRegister';
import Presentation from './components/Instructions/Presentation';
import InfoPage from './components/InfoPage/InfoPage';
import FormInfoPage from './components/InfoPage/FormInfoPage';
import FormApiPage from './components/FormApiPage';
import LoginPage from './components/LoginPage'

import language from './language/language.js'



function App() {
  const {userData, tg, telegram_id} = useTelegram()
  const lang = (userData?.data?.lang ? userData.data.lang:'ru' )


  useEffect(() => {
    tg.ready()
    
  },[])

  const onClose = () => {
    tg.close()
  }

  return (
    <div>
    <div className="App"> 
      
      {/* <Header/> */}
      <Routes>
        <Route index element={<Main userData={userData} telegram_id={telegram_id} tg={tg}/>}/>

        <Route path={'/finances/trade'} element={<Finances wtype='trade' userData={userData} telegram_id={telegram_id}/>} />
        <Route path={'/finances/club'} element={<Finances wtype='club' userData={userData}/>} />
        <Route path={'/instructions'} element={<Instructions/>} userData={userData}/>
        <Route path={'/presentation'} element={<Presentation/>} userData={userData}/>

        <Route path={'/referals'} element={<InfoPage img={language[lang].referal_img} link={'https://t.me/+DQS1J9SyGtQyNDc6'} userData={userData}/>} />
        <Route path={'/investors'} element={<InfoPage img={language[lang].investor_img} link={'/investors/form'} userData={userData}/>}/>
        <Route path={'/investors/form'} element={<FormInfoPage userData={userData}/>} />

       

        <Route path={'/update/api'} element={<FormApiPage type='add' userData={userData}/>} />
        <Route path={'/add/api'} element={<TradeRegister type='add' userData={userData}/>} />
        <Route path={'/form'} element={<Register/>} />
        <Route path={'/form/:recommended'} element={<Register/>} />
        


        {/* <Route path={'/login'} element={<ProtectedLoginRoute element={<LoginPage setIsAuthenticated={setIsAuthenticated}/>} isAuthenticated={isAuthenticated}></ProtectedLoginRoute>} />
        <Route path={'/admin/user/:id_telegram'} element={<ProtectedRoute element={<Admin><AdminUser>User</AdminUser></Admin>} isAuthenticated={isAuthenticated} />} />
        <Route path={'/admin/request/'} element={<ProtectedRoute element={<Admin><TradeAdminList></TradeAdminList></Admin>} isAuthenticated={isAuthenticated} />} />
        <Route path={'/admin/discord/'} element={<ProtectedRoute element={<Admin><DiscordList></DiscordList></Admin>} isAuthenticated={isAuthenticated} />} />
        <Route path={'/admin/finances/'} element={<ProtectedRoute element={<Admin><FinancesAdminList></FinancesAdminList></Admin>} isAuthenticated={isAuthenticated} />} />
        <Route path={'/admin/history/'} element={<ProtectedRoute element={<Admin><HistoryAdminList></HistoryAdminList></Admin>} isAuthenticated={isAuthenticated} />} />
        <Route path={'/admin/alerts/'} element={<ProtectedRoute element={<Admin><AlertsAdmin></AlertsAdmin></Admin>} isAuthenticated={isAuthenticated} />} />
        <Route path={'/admin'} element={<ProtectedRoute element={<Admin><AdminList></AdminList></Admin>} isAuthenticated={isAuthenticated} />} /> */}

        
      </Routes>
    </div>
    </div>
  );
}




export default App;

import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import language from '../../language/language.js'


export default function Slider({ userData }) {
    const [open, setOpen] = useState(false);
    const lang = (userData.data.lang ? userData.data.lang:'ru' )
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };



    // if (telegram_id === 1111) {
    //     return (
    //         <>
    //             <Swiper
    //                 spaceBetween={8}
    //                 slidesPerView={1.5}

    //             >
    //                 <SwiperSlide>
    //                     <div className="info__presentation">
    //                         <div className="info__presentation--leftBlock">
    //                             <h5>{language[lang].slider_slide_1}</h5>
    //                             <a href='/instructions'>
    //                                 <span>
    //                                     {language[lang].slider_sabmit}
    //                                 </span>


    //                                 <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                     <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                     <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 </svg>
    //                             </a>
    //                         </div>
    //                         <div className="info__svg">
    //                             <svg width="52.000000" height="49.000000" viewBox="0 0 52 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <path id="Vector" d="M50.79 5.08C50.79 5.26 50.78 5.43 50.76 5.6C50.63 7.13 49.9 8.54 48.73 9.54C47.57 10.54 46.05 11.05 44.52 10.95C42.99 10.84 41.56 10.15 40.53 9C39.51 7.86 38.97 6.36 39.04 4.82C39.11 3.29 39.77 1.84 40.89 0.79C42.02 -0.26 43.5 -0.83 45.04 -0.79C46.58 -0.76 48.04 -0.12 49.11 0.97C50.18 2.07 50.79 3.55 50.79 5.08Z" fill="#FFD0A4" />
    //                                 <path id="Vector" d="M45.65 5.53C45.27 5.83 44.74 6.42 44.9 7.72C44.72 8.23 44.09 9.2 44.09 9.66C44.09 10.13 43.33 10.21 43.25 10.08C43.16 9.96 43.12 8.36 42.66 8.06C42.2 7.77 42.28 6.42 42.28 6.42C42.28 6.42 42.45 5.91 41.9 5.58C41.35 5.24 40.97 4.44 41.46 3.97C41.94 3.51 40.64 3.22 40.47 2.88C40.4 2.74 40.12 2.42 39.83 2.1C40.46 1.03 41.41 0.19 42.55 -0.31C42.98 0.14 43.35 0.72 43.25 1.32C43.27 1.62 44.55 2.08 43.92 2.71C43.29 3.34 42.44 4.23 43.92 4.52C45.4 4.82 46.03 5.24 45.65 5.53Z" fill="#FB902E" />
    //                                 <path id="Vector" d="M49.02 0.88C48.7 1.54 47.91 2.08 47.46 2.08C46.96 1.53 45.86 2.29 45.76 1.41C45.7 0.89 46.29 0.09 46.79 -0.49C47.62 -0.21 48.39 0.26 49.02 0.88Z" fill="#FB902E" />
    //                                 <path id="Vector" d="M50.76 5.6C50.64 7.05 49.97 8.41 48.9 9.4C48.71 9.21 48.54 8.99 48.6 8.82C48.73 8.44 49.24 7.64 49.15 7.13C49.07 6.66 49.5 5.24 50.76 5.6Z" fill="#FB902E" />
    //                                 <path id="Vector" d="M41.2 34.8C41.01 35.96 40.7 37.1 40.27 38.2C37.47 45.36 29.96 49.85 22.06 48.56C17.7 47.85 13.8 45.43 11.22 41.85C8.64 38.26 7.59 33.79 8.3 29.43C8.34 29.21 8.38 28.99 8.42 28.77C10.2 20.05 18.57 14.22 27.43 15.67C30.08 16.1 32.58 17.16 34.72 18.77C35.43 19.29 36.09 19.87 36.7 20.51C38.52 22.37 39.87 24.64 40.65 27.12C41.43 29.6 41.62 32.23 41.2 34.8L41.2 34.8Z" fill="#FFB877" />
    //                                 <path id="Vector" d="M40.27 38.2C37.09 40.24 33.34 42.13 29.21 43.69C25.01 45.28 20.87 46.36 17.09 46.92C8.19 48.25 1.2 46.76 -0.37 42.6C-1.85 38.71 1.78 33.44 8.42 28.77C8.38 28.99 8.34 29.21 8.3 29.43C8.17 30.24 8.1 31.06 8.08 31.88C4.26 34.84 2.26 37.73 3.05 39.81C3.81 41.83 7.06 42.68 11.67 42.44C16.18 42.2 21.99 40.91 28.08 38.6C33.35 36.61 37.97 34.19 41.41 31.75C46.01 28.5 48.51 25.22 47.65 22.94C46.76 20.59 42.53 19.81 36.7 20.51C36.09 19.87 35.43 19.29 34.72 18.77C42.89 17.82 49.18 19.36 50.67 23.29C52.26 27.48 47.92 33.28 40.27 38.2Z" fill="#FB902E" />
    //                             </svg>
    //                         </div>
    //                     </div>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <div className="info__presentation">
    //                         <div className="info__presentation--leftBlock">
    //                             <h5>{language[lang].slider_slide_2}</h5>
    //                             <a href="/media/SC%20presa.pdf" download='FI presentation.pdf'>
    //                                 <span>
    //                                     {language[lang].slider_sabmit}
    //                                 </span>
    //                                 <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                     <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 </svg>
    //                             </a>
    //                         </div>
    //                         <div className="info__svg">

    //                             <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >

    //                                 <path id="Vector" d="M24.56 42.23C22.01 42.11 19.12 39.06 20.39 38.25C21.66 37.44 21.51 35.74 21.05 35.51C18.19 34.39 17.49 31.88 16.88 28.68C16.26 25.47 16.61 15.28 16.61 15.28L20.2 13.47L30.12 12.89L32.94 17.56C32.94 17.56 31.9 20.35 31.82 23.14C32.49 22.6 33.67 21.86 34.56 22.39C35.13 22.73 35.47 23.27 35.62 23.86C35.81 24.64 35.67 25.53 35.25 26.25C34.96 26.75 34.63 27.25 34.16 27.63C33.5 28.17 32.58 28.48 31.16 28.23C31.22 28.99 32.7 29.02 32.7 29.8C32.7 30.53 32.68 31.65 32.7 32.08C32.94 35.55 34.83 36.75 35.79 37.91C36.76 39.06 27.11 42.34 24.56 42.23Z" fill="#FEE3CA" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M20 24.8C20 26.5 18.74 27.87 17.19 27.87C15.63 27.87 14.37 26.5 14.37 24.8C14.37 23.11 15.63 21.74 17.19 21.74C18.74 21.74 20 23.11 20 24.8Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M19.46 24.8C19.46 26.16 18.44 27.26 17.19 27.26C15.93 27.26 14.91 26.16 14.91 24.8C14.91 23.45 15.93 22.35 17.19 22.35C18.44 22.35 19.46 23.45 19.46 24.8Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M27.34 25.24C27.34 26.82 26.12 28.1 24.62 28.1C23.11 28.1 21.9 26.82 21.9 25.24C21.9 23.67 23.11 22.39 24.62 22.39C26.12 22.39 27.34 23.67 27.34 25.24Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M26.78 25.24C26.78 26.58 25.81 27.66 24.62 27.66C23.42 27.66 22.45 26.58 22.45 25.24C22.45 23.91 23.42 22.83 24.62 22.83C25.81 22.83 26.78 23.91 26.78 25.24Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M19.87 23.86C19.87 23.86 21.27 23.33 22.19 24.34C22.19 24.51 22.05 24.8 22.05 24.8C22.05 24.8 20.81 23.83 19.87 24.57C19.71 24.26 19.62 23.93 19.87 23.86Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M27.11 24.5L31.77 22.83L31.73 23.24L27.26 25.11L27.11 24.5Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M8.16 45.05C8.06 44.77 8.02 44.47 8.04 44.18C8.07 43.88 8.16 43.59 8.31 43.33L9.5 41.23C9.5 41.23 9.66 40.4 13.16 39.91C16.66 39.42 20.03 38.54 20.39 38.25C20.39 38.25 26.57 43.57 35.68 37.78C35.68 37.78 43.11 41.97 44.81 42.13C45.81 42.22 46.59 44.18 47.06 45.79C47.14 46.05 47.16 46.33 47.12 46.61C47.09 46.88 47 47.15 46.86 47.39C46.72 47.63 46.53 47.84 46.3 48C46.08 48.16 45.82 48.28 45.55 48.34C38.03 50.01 11.9 55.04 8.16 45.05Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M34.16 27.63C33.28 29.73 32.68 30.61 31.97 30.53C31.23 30.45 31 29.26 31.05 28.21C32.53 28.5 33.49 28.18 34.16 27.63Z" fill="#FB902E" fill-opacity="0.800000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M35.64 23.81C35.63 23.83 35.63 23.85 35.62 23.86C35.47 23.26 35.13 22.73 34.56 22.39C33.65 21.84 32.43 22.64 31.76 23.18C31.89 20.75 31.68 18 30.35 17.41C28.03 16.36 27.94 16.6 26.5 16.67C25.15 16.75 21.84 16.95 21.27 16.63C21.65 16.98 23.4 18.47 26.19 18.6C26.5 18.83 26.3 19.03 25.99 18.99C25.68 18.95 21.47 18.87 18.31 14.9C18.31 14.9 16.47 18.33 16.51 21.42C16.51 21.42 15.33 19.1 16.07 15.78C16.51 13.31 17.65 13.55 17.65 13.55C17.65 13.55 16.88 8.03 22.17 8.53C23.71 8.72 26.34 11 28.11 10.96C29.89 10.92 31.51 11.04 31.51 11.04C31.51 11.04 34.02 10.65 34.44 14.05C34.46 14.73 34.95 15.04 36.29 15.21C37.68 15.38 37.45 18.95 35.64 23.81Z" fill="#FFA552" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M48.26 53.17C51.81 53.17 54.69 50.29 54.69 46.74C54.69 43.19 51.81 40.32 48.26 40.32C44.72 40.32 41.84 43.19 41.84 46.74C41.84 50.29 44.72 53.17 48.26 53.17Z" fill="#FB902E" fill-opacity="0.800000" fill-rule="nonzero" />
    //                                 <path id="Vector (Stroke)" d="M48.26 42.48C45.91 42.48 44 44.39 44 46.74C44 49.1 45.91 51 48.26 51C50.62 51 52.53 49.1 52.53 46.74C52.53 44.39 50.62 42.48 48.26 42.48ZM39.67 46.74C39.67 42 43.52 38.15 48.26 38.15C53.01 38.15 56.86 42 56.86 46.74C56.86 51.49 53.01 55.34 48.26 55.34C43.52 55.34 39.67 51.49 39.67 46.74Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M50.74 46.21L48.91 46.21C48.9 46.21 48.88 46.2 48.87 46.2C48.86 46.19 48.84 46.18 48.83 46.17C48.82 46.16 48.82 46.15 48.81 46.14C48.81 46.13 48.8 46.11 48.8 46.1L48.8 44.27C48.8 44.13 48.74 43.99 48.64 43.89C48.54 43.79 48.41 43.73 48.26 43.73C48.12 43.73 47.99 43.79 47.89 43.89C47.78 43.99 47.73 44.13 47.73 44.27L47.73 46.1C47.73 46.13 47.71 46.15 47.69 46.17C47.67 46.19 47.65 46.21 47.62 46.21L45.79 46.21C45.65 46.21 45.51 46.26 45.41 46.36C45.31 46.46 45.25 46.6 45.25 46.74C45.25 46.89 45.31 47.02 45.41 47.12C45.51 47.23 45.65 47.28 45.79 47.28L47.62 47.28C47.63 47.28 47.65 47.28 47.66 47.29C47.67 47.3 47.68 47.3 47.69 47.31C47.7 47.32 47.71 47.34 47.72 47.35C47.72 47.36 47.73 47.38 47.73 47.39L47.73 49.22C47.73 49.36 47.78 49.5 47.89 49.6C47.99 49.7 48.12 49.75 48.26 49.75C48.41 49.75 48.54 49.7 48.64 49.6C48.74 49.5 48.8 49.36 48.8 49.22L48.8 47.39C48.8 47.38 48.81 47.36 48.81 47.35C48.82 47.34 48.82 47.32 48.83 47.31C48.84 47.3 48.86 47.3 48.87 47.29C48.88 47.28 48.9 47.28 48.91 47.28L50.74 47.28C50.88 47.28 51.02 47.23 51.12 47.12C51.22 47.02 51.28 46.89 51.28 46.74C51.28 46.6 51.22 46.46 51.12 46.36C51.02 46.26 50.88 46.21 50.74 46.21Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
    //                             </svg>



    //                         </div>
    //                     </div>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <div className="info__presentation">
    //                         <div className="info__presentation--leftBlock">
    //                             <h5>Financial Institution</h5>
    //                             <a href='https://t.me/+VgRH-xCHHWhmNmU6'>
    //                                 <span>
    //                                 {language[lang].slider_sabmit}
    //                                 </span>

    //                                 <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                     <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 </svg>
    //                             </a>
    //                         </div>
    //                         <div className="info__svg">
    //                             <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
    //                                 <rect id="Social 05" width="64.000000" height="64.000000" fill="#FFFFFF" fill-opacity="0" />
    //                                 <path id="Vector" d="M33.18 22.5C33.18 22.5 17.65 39.95 17.47 41.89C17.47 41.89 18.17 42.2 18.95 43.1C19.86 44.15 20.94 44.55 21.49 44.26C22.27 43.83 22.2 43.25 22.2 43.25L34.19 47.59C34.19 47.59 38.43 46.26 47.01 37.31L49.3 34.05L33.18 22.5Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M15.38 25.02C15.38 25.02 17.29 26.39 19.58 25.1L26.76 20.98C26.76 20.98 29.21 19.17 31.73 20.91C31.73 20.91 29.44 26.46 34.15 27.41C34.15 27.41 38.75 29.52 41.18 31.68C42.4 32.77 45.02 34.61 46.26 35.66C46.6 35.94 47.28 36.35 47.63 36.77C48.11 37.35 49.03 38.62 47.55 39.85C47.55 39.85 46.57 41 44.6 39.79C42.77 38.66 39.58 36.04 37.64 34.82C37.54 34.75 37.06 34.66 37.73 35.31C38.55 36.11 42.4 38.96 42.4 38.96C42.4 38.96 43.84 39.96 44.19 40.47C44.49 40.91 44.95 41.89 44.09 42.96C44.09 42.96 42.86 44.57 40.77 43.29L35.95 39.92C35.95 39.92 34.92 39.26 35.75 40.23C35.75 40.23 40.64 43.66 40.83 44.19C41.38 45.68 40.32 47.59 37.93 46.67L32.42 43.65C32.42 43.65 31.91 43.58 32.32 43.98C32.32 43.98 37.44 46.62 37.8 46.91C38.26 47.29 37.14 49.08 34.8 48.36C31.47 47.34 24.74 45.5 21.49 44.26C20.34 43.82 17.99 42.66 17.09 42.11C17.09 42.11 13.45 39.82 8.47 39.72L12.06 24.43L15.38 25.02Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M20.94 45.29C20.94 45.29 22 43.9 22.65 43.4C23.96 42.38 26.34 43.37 26.46 44.62C26.46 44.62 26.97 45.84 24.85 47.65C24.45 47.99 23.2 48.78 21.38 47.28C21.38 47.28 20.28 46.28 20.94 45.29Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M23.05 32.94C23.05 32.94 26.1 35.36 29.25 28.18C29.25 28.18 30.93 28.76 33.54 27.25L34.08 26.41C34.08 26.41 28.79 26.97 28.81 27.07C28.83 27.18 23.05 32.94 23.05 32.94Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M25.48 48.01C25.44 47.09 26.73 45.39 27.29 44.8C27.96 44.1 30.58 43.42 30.89 46.22C30.89 46.22 30.73 48.17 29.27 49.29C28.58 49.82 25.55 49.96 25.48 48.01Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M31.35 46.61C31.8 45.9 34.01 45.56 34.06 47.6C34.08 48.37 33.68 49.65 32.53 50.15C31.7 50.52 29.5 49.92 29.92 49.03C30.2 48.41 30.34 48.22 31.35 46.61Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M17.03 43.47C17.23 43.05 17.54 42.64 18.18 42.13C18.56 41.82 19.64 41.7 20.29 42.11C20.76 42.4 21.67 43.08 21.47 43.69C21.08 44.84 20.41 46.4 18.98 45.95C18.98 45.95 16.18 45.35 17.03 43.47Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M14.81 23.52L11.88 42.57L2.47 40.76L6.64 21.35L14.81 23.52Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M18.07 42.83C18.07 42.83 17.61 44.13 18.43 44.68C18.43 44.68 19.48 45.94 20.78 43.86C21 43.51 20.51 42.9 19.65 42.67C19.65 42.67 18.84 42.36 18.07 42.83Z" fill="#FFFBE8" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M22.24 45.17C22.24 45.17 22.77 43.98 23.23 43.79C23.69 43.6 25.1 43.57 25.69 44.95C25.8 45.19 25.43 45.69 25.26 45.95C24.96 46.44 24.17 47.16 23.26 46.67C23.26 46.67 22.09 46.24 22.24 45.17Z" fill="#FFFBE8" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M27.03 46.2C27.15 45.85 27.65 45.22 28.09 45.17C28.83 45.1 30.54 45.65 30.09 46.74C30.09 46.74 29.7 47.67 28.87 47.88C28.87 47.88 27.43 48.4 26.9 47.06C26.82 46.86 26.95 46.4 27.03 46.2Z" fill="#FFFBE8" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M30.94 48.29C31.15 48.83 32.06 49.37 32.62 48.87C32.84 48.66 33.31 47.98 33.33 47.68C33.38 47.16 32.37 46.08 31.48 47.09C31.27 47.33 30.82 48 30.94 48.29Z" fill="#FFFBE8" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M35.26 20.41C35.26 20.41 32.18 19.44 29.83 21.81L25.88 24.22C25.88 24.22 24.2 24.78 23.68 27.04L21.88 30.77C21.88 30.77 20.43 33.32 24.66 32.97C24.66 32.97 27.08 31.95 29 28.07L28.98 27.57C28.98 27.57 29.26 28.18 30.65 27.94C30.65 27.94 32.09 27.44 34.09 26.78C34.09 26.78 34.41 27.17 36.33 28.22C36.33 28.22 41.01 30.67 42.1 31.98L47.71 36.25C47.71 36.25 48.58 35.9 50.74 32.53L55.58 30.81L50.51 16.98C50.51 16.98 42.78 20 40.52 20.23L35.26 20.41Z" fill="#FF9C41" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M47.13 17.31L53.81 33.04L60.92 30.28L53.94 14.36L47.13 17.31Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M22.77 28.91C22.77 28.91 24.47 28.27 24.97 29.45C24.97 29.45 24.46 31.54 23.88 32C23.43 32.36 21.94 32.51 21.65 31.74C21.65 31.74 21.46 30.95 22.77 28.91Z" fill="#FFFBE8" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M38.08 29.19C38.08 29.19 41.23 30.48 44.42 27.93C44.42 27.93 42.23 30.88 39.28 29.9L38.08 29.19Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M8.69 38.9C8.71 39.11 8.67 39.32 8.57 39.5C8.48 39.68 8.33 39.83 8.14 39.93C7.96 40.03 7.75 40.07 7.55 40.05C7.34 40.03 7.14 39.95 6.98 39.82C6.82 39.69 6.7 39.51 6.64 39.31C6.58 39.11 6.58 38.9 6.64 38.7C6.7 38.5 6.82 38.33 6.98 38.2C7.14 38.06 7.34 37.98 7.54 37.96C7.82 37.93 8.1 38.02 8.31 38.19C8.53 38.37 8.66 38.62 8.69 38.9Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M57.48 28.75C57.5 28.96 57.46 29.16 57.36 29.35C57.26 29.53 57.11 29.68 56.93 29.78C56.75 29.88 56.54 29.92 56.33 29.9C56.13 29.88 55.93 29.8 55.77 29.66C55.61 29.53 55.49 29.36 55.43 29.16C55.37 28.96 55.37 28.75 55.43 28.55C55.49 28.35 55.61 28.18 55.77 28.04C55.93 27.91 56.13 27.83 56.33 27.81C56.47 27.8 56.61 27.81 56.74 27.85C56.87 27.89 56.99 27.95 57.1 28.04C57.21 28.13 57.3 28.24 57.36 28.36C57.43 28.48 57.47 28.61 57.48 28.75Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
    //                             </svg>
    //                         </div>
    //                     </div>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <div className="info__presentation">
    //                         <div className="info__presentation--leftBlock">
    //                             <h5>Smart Club</h5>
    //                             <a href="https://t.me/+6BVlYToXqjA0Njli">
    //                                 <span>
    //                                 {language[lang].slider_sabmit}
    //                                 </span>
    //                                 <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                     <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 </svg>
    //                             </a>
    //                         </div>
    //                         <div className="info__svg">
    //                             <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                 <rect id="Social 05" width="64.000000" height="64.000000" fill="#FFFFFF" fill-opacity="0" />
    //                                 <path id="Vector" d="M25.71 39.38C25.71 46.91 31.81 53.02 39.35 53.02C41.11 53.02 42.84 52.69 44.47 52.03C44.5 52.03 44.54 52.03 44.57 52.03L50.54 52.03C50.73 52.03 50.93 51.99 51.1 51.92C51.28 51.85 51.44 51.74 51.58 51.6C51.71 51.47 51.82 51.31 51.89 51.13C51.97 50.95 52.01 50.76 52.01 50.57L52.01 44.48C52.66 42.86 53 41.13 52.99 39.38C52.99 31.84 46.89 25.73 39.35 25.73C31.81 25.73 25.71 31.84 25.71 39.38Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M44.81 29.7C44.81 39.16 37.15 46.82 27.69 46.82C25.49 46.83 23.31 46.4 21.27 45.57C21.23 45.58 21.19 45.58 21.15 45.58L13.65 45.58C13.41 45.58 13.17 45.53 12.95 45.44C12.73 45.35 12.53 45.21 12.36 45.04C12.18 44.87 12.05 44.67 11.96 44.45C11.87 44.23 11.82 43.99 11.82 43.75L11.82 36.11C11 34.07 10.57 31.9 10.58 29.7C10.58 20.25 18.24 12.59 27.69 12.59C37.15 12.59 44.81 20.25 44.81 29.7Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M22.24 30.7C22.24 32.07 21.13 33.18 19.76 33.18C18.39 33.18 17.28 32.07 17.28 30.7C17.28 29.33 18.39 28.22 19.76 28.22C21.13 28.22 22.24 29.33 22.24 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M29.43 30.7C29.43 32.07 28.32 33.18 26.95 33.18C25.58 33.18 24.47 32.07 24.47 30.7C24.47 29.33 25.58 28.22 26.95 28.22C28.32 28.22 29.43 29.33 29.43 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M36.87 30.7C36.87 32.07 35.76 33.18 34.39 33.18C33.02 33.18 31.91 32.07 31.91 30.7C31.91 29.33 33.02 28.22 34.39 28.22C35.76 28.22 36.87 29.33 36.87 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M42.08 21.52C44.55 21.52 46.55 19.52 46.55 17.05C46.55 14.59 44.55 12.59 42.08 12.59C39.62 12.59 37.62 14.59 37.62 17.05C37.62 19.52 39.62 21.52 42.08 21.52Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector (Stroke)" d="M42.08 15.1C41.01 15.1 40.14 15.98 40.14 17.05C40.14 18.13 41.01 19 42.08 19C43.16 19 44.03 18.13 44.03 17.05C44.03 15.98 43.16 15.1 42.08 15.1ZM35.1 17.05C35.1 13.2 38.23 10.07 42.08 10.07C45.94 10.07 49.07 13.2 49.07 17.05C49.07 20.91 45.94 24.04 42.08 24.04C38.23 24.04 35.1 20.91 35.1 17.05Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector (Stroke) (Stroke)" d="M34.68 17.05C34.68 12.96 37.99 9.65 42.08 9.65C46.17 9.65 49.49 12.96 49.49 17.05C49.49 21.14 46.17 24.46 42.08 24.46C37.99 24.46 34.68 21.14 34.68 17.05ZM42.08 10.49C38.46 10.49 35.52 13.43 35.52 17.05C35.52 20.68 38.46 23.61 42.08 23.61C45.71 23.61 48.65 20.68 48.65 17.05C48.65 13.43 45.71 10.49 42.08 10.49ZM42.08 15.53C41.24 15.53 40.56 16.21 40.56 17.05C40.56 17.9 41.24 18.58 42.08 18.58C42.93 18.58 43.61 17.9 43.61 17.05C43.61 16.21 42.93 15.53 42.08 15.53ZM39.71 17.05C39.71 15.74 40.78 14.68 42.08 14.68C43.39 14.68 44.45 15.74 44.45 17.05C44.45 18.36 43.39 19.42 42.08 19.42C40.78 19.42 39.71 18.36 39.71 17.05Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M43.5 15.73C43.44 15.5 43.34 15.29 43.19 15.11C43.06 14.95 42.9 14.83 42.71 14.76C42.3 14.62 41.86 14.62 41.46 14.76C41.27 14.83 41.1 14.95 40.97 15.11C40.83 15.29 40.72 15.5 40.66 15.73C40.59 16.04 40.55 16.35 40.56 16.67L40.56 17.42C40.55 17.74 40.59 18.05 40.67 18.36C40.72 18.59 40.83 18.81 40.98 18.99C41.11 19.15 41.27 19.27 41.46 19.34C41.86 19.49 42.31 19.49 42.71 19.34C42.9 19.27 43.06 19.15 43.19 18.99C43.34 18.8 43.44 18.59 43.5 18.36C43.58 18.05 43.61 17.74 43.61 17.42L43.61 17.42L43.61 16.67C43.62 16.35 43.58 16.04 43.5 15.73ZM42.83 17.53C42.83 17.73 42.82 17.93 42.78 18.13C42.76 18.27 42.71 18.41 42.64 18.53C42.58 18.63 42.5 18.71 42.41 18.76C42.31 18.81 42.2 18.83 42.09 18.83C41.98 18.83 41.87 18.81 41.77 18.76C41.67 18.71 41.58 18.63 41.53 18.53C41.45 18.41 41.4 18.27 41.38 18.13C41.34 17.94 41.32 17.73 41.33 17.53L41.33 16.55C41.32 16.35 41.34 16.15 41.38 15.95C41.4 15.81 41.45 15.68 41.52 15.56C41.58 15.47 41.66 15.39 41.76 15.35C41.86 15.3 41.97 15.28 42.08 15.28C42.19 15.28 42.3 15.3 42.4 15.35C42.5 15.39 42.58 15.47 42.63 15.56C42.71 15.68 42.76 15.81 42.78 15.95C42.82 16.15 42.83 16.35 42.83 16.55L42.83 17.53L42.83 17.53Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
    //                             </svg>

    //                         </div>
    //                     </div>
    //                 </SwiperSlide>
    //                 <SwiperSlide>
    //                     <div className="info__presentation">
    //                         <div className="info__presentation--leftBlock">
    //                             <h5>{language[lang].slider_slide_3}?</h5>
    //                             <a href='https://t.me/MANAGER_FIN_INST'>
    //                                 <span>
    //                                 {language[lang].slider_sabmit}
    //                                 </span>

    //                                 <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                     <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 </svg>
    //                             </a>
    //                         </div>
    //                         <div className="info__svg">
    //                             <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
    //                                 <path id="Vector" d="M57.03 16.69C56.96 16.76 56.89 16.83 56.81 16.89L51.02 21.3L46.15 33.48L34.22 41.1C34.22 41.1 32.17 42.61 30.39 42.65C29.98 42.77 28.75 43.16 28.03 43.84C27.8 44.06 25.81 48.94 22.75 55.82C22.6 56.16 22.32 56.43 21.98 56.59C21.63 56.74 21.24 56.75 20.89 56.63C17.67 55.54 10.48 53.13 7.08 51.59C6.87 51.5 6.68 51.35 6.54 51.17C6.4 50.99 6.31 50.78 6.27 50.55C6.23 50.32 6.25 50.09 6.32 49.87C6.39 49.66 6.51 49.46 6.68 49.3C10.95 45.17 19.99 35.69 19.99 35.69L20.64 34.54C20.64 34.54 24.93 23.53 26.15 22.88C26.63 22.42 29.42 18.84 30.59 16.29C30.84 15.92 32 14.41 32.98 13.1C33.67 12.16 34.27 11.32 34.38 11.08C34.4 11.03 34.44 10.98 34.48 10.94C34.48 10.94 34.48 10.94 34.48 10.94C34.94 10.52 36.34 10.71 36.3 12.39C36.26 14.25 34.35 18 34.01 18.08C33.81 18.48 33.19 20.65 32.13 22.46C32.03 22.74 31.87 23.46 32.67 23.33C33.48 23.2 52.46 15.67 52.46 15.67L54.84 14.37C55.19 14.18 55.59 14.1 55.99 14.17C56.46 14.25 56.88 14.51 57.16 14.9C57.76 15.7 57.29 16.4 57.03 16.69Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M33.89 13.46C33.56 13.47 33.23 13.29 32.98 13.1C33.67 12.16 34.27 11.32 34.38 11.08C34.4 11.03 34.44 10.98 34.48 10.94C35.19 11.89 33.99 13.37 33.89 13.46Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M39.59 7.9L52.85 12.63C53.78 12.96 54.26 13.98 53.93 14.91L43.98 42.76C43.65 43.68 42.63 44.16 41.71 43.83L28.44 39.1C27.52 38.77 27.04 37.75 27.37 36.82L37.31 8.97C37.64 8.05 38.66 7.57 39.59 7.9Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M39.75 8.89L52.1 13.3C52.79 13.55 53.15 14.31 52.91 15.01L43.25 42.03C43.01 42.73 42.24 43.09 41.55 42.84L29.2 38.43C28.51 38.18 28.14 37.42 28.39 36.73L38.04 9.7C38.29 9 39.06 8.64 39.75 8.89Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M49.9 22.46L52.16 22.62C53.28 22.7 54.13 23.67 54.05 24.79C53.97 25.91 53 26.76 51.88 26.68L49.61 26.52C48.49 26.44 47.64 25.47 47.72 24.35C47.8 23.23 48.78 22.38 49.9 22.46Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M48.24 26.57L51.11 26.77C52.02 26.83 52.71 27.62 52.64 28.53C52.58 29.44 51.79 30.12 50.88 30.06L48.01 29.86C47.1 29.79 46.42 29 46.48 28.1C46.55 27.19 47.34 26.5 48.24 26.57Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M47.28 30.13L48.97 30.25C49.66 30.3 50.18 30.9 50.13 31.58C50.08 32.27 49.49 32.79 48.8 32.74L47.1 32.62C46.42 32.57 45.9 31.98 45.95 31.29C46 30.6 46.59 30.08 47.28 30.13Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M46.96 30.57L47.95 30.64C48.12 30.65 48.25 30.8 48.24 30.97L48.17 31.86C48.16 32.03 48.01 32.16 47.84 32.15L46.85 32.08C46.68 32.06 46.55 31.92 46.56 31.74L46.62 30.86C46.64 30.69 46.79 30.56 46.96 30.57Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M48.05 27.33L49.16 27.41C49.53 27.43 49.8 27.75 49.78 28.12L49.74 28.66C49.71 29.02 49.4 29.3 49.03 29.27L47.92 29.2C47.55 29.17 47.27 28.85 47.3 28.48L47.34 27.95C47.36 27.58 47.68 27.3 48.05 27.33Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M49.95 23.17L50.85 23.24C51.34 23.27 51.71 23.7 51.67 24.19L51.62 24.89C51.59 25.38 51.16 25.75 50.67 25.72L49.78 25.65C49.29 25.62 48.92 25.19 48.95 24.7L49 24C49.04 23.51 49.46 23.14 49.95 23.17Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
    //                                 <path id="Vector" d="M43.1 19.73C41.1 18.99 39.74 19.83 39.44 20.64C39.23 21.21 39.62 21.66 40.02 21.81C40.82 22.11 40.92 20.84 42.43 21.4C43.17 21.68 43.65 22.22 43.39 22.91C43.1 23.71 42.1 23.86 41.45 24.09C40.89 24.31 40.1 24.71 39.62 25.98C39.34 26.75 39.46 27.05 40.07 27.28C40.8 27.55 41.07 27.28 41.17 26.99C41.46 26.22 41.64 25.78 42.69 25.45C43.21 25.28 44.84 24.74 45.36 23.35C45.87 21.95 45 20.43 43.1 19.73Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                                 <path id="Vector" d="M39.71 28.31C39.56 28.25 39.4 28.23 39.24 28.23C39.08 28.24 38.92 28.28 38.78 28.34C38.63 28.41 38.5 28.5 38.39 28.62C38.28 28.74 38.2 28.88 38.14 29.03C38.09 29.18 38.06 29.34 38.07 29.5C38.08 29.66 38.11 29.81 38.18 29.96C38.25 30.1 38.34 30.23 38.46 30.34C38.58 30.45 38.71 30.54 38.86 30.59C39.17 30.7 39.5 30.69 39.8 30.56C40.09 30.42 40.32 30.18 40.43 29.87C40.54 29.57 40.53 29.23 40.39 28.94C40.26 28.65 40.01 28.42 39.71 28.31Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
    //                             </svg>

    //                         </div>
    //                     </div>
    //                 </SwiperSlide>
    //             </Swiper>

    //         </>
    //     )
    // }
    return (

        <>
            <Swiper
                spaceBetween={8}
                slidesPerView={1.5}
            >
                 <SwiperSlide>
                    <div className="info__presentation">
                        <div className="info__presentation--leftBlock">
                            <h5>{language[lang].slider_slide_3}?</h5>
                            <a href='#' onClick={showDrawer}>
                                <span>
                                {language[lang].slider_sabmit}
                                </span>

                                <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                </svg>
                            </a>
                        </div>
                        <div className="info__svg">
                            <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                <path id="Vector" d="M57.03 16.69C56.96 16.76 56.89 16.83 56.81 16.89L51.02 21.3L46.15 33.48L34.22 41.1C34.22 41.1 32.17 42.61 30.39 42.65C29.98 42.77 28.75 43.16 28.03 43.84C27.8 44.06 25.81 48.94 22.75 55.82C22.6 56.16 22.32 56.43 21.98 56.59C21.63 56.74 21.24 56.75 20.89 56.63C17.67 55.54 10.48 53.13 7.08 51.59C6.87 51.5 6.68 51.35 6.54 51.17C6.4 50.99 6.31 50.78 6.27 50.55C6.23 50.32 6.25 50.09 6.32 49.87C6.39 49.66 6.51 49.46 6.68 49.3C10.95 45.17 19.99 35.69 19.99 35.69L20.64 34.54C20.64 34.54 24.93 23.53 26.15 22.88C26.63 22.42 29.42 18.84 30.59 16.29C30.84 15.92 32 14.41 32.98 13.1C33.67 12.16 34.27 11.32 34.38 11.08C34.4 11.03 34.44 10.98 34.48 10.94C34.48 10.94 34.48 10.94 34.48 10.94C34.94 10.52 36.34 10.71 36.3 12.39C36.26 14.25 34.35 18 34.01 18.08C33.81 18.48 33.19 20.65 32.13 22.46C32.03 22.74 31.87 23.46 32.67 23.33C33.48 23.2 52.46 15.67 52.46 15.67L54.84 14.37C55.19 14.18 55.59 14.1 55.99 14.17C56.46 14.25 56.88 14.51 57.16 14.9C57.76 15.7 57.29 16.4 57.03 16.69Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M33.89 13.46C33.56 13.47 33.23 13.29 32.98 13.1C33.67 12.16 34.27 11.32 34.38 11.08C34.4 11.03 34.44 10.98 34.48 10.94C35.19 11.89 33.99 13.37 33.89 13.46Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M39.59 7.9L52.85 12.63C53.78 12.96 54.26 13.98 53.93 14.91L43.98 42.76C43.65 43.68 42.63 44.16 41.71 43.83L28.44 39.1C27.52 38.77 27.04 37.75 27.37 36.82L37.31 8.97C37.64 8.05 38.66 7.57 39.59 7.9Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M39.75 8.89L52.1 13.3C52.79 13.55 53.15 14.31 52.91 15.01L43.25 42.03C43.01 42.73 42.24 43.09 41.55 42.84L29.2 38.43C28.51 38.18 28.14 37.42 28.39 36.73L38.04 9.7C38.29 9 39.06 8.64 39.75 8.89Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M49.9 22.46L52.16 22.62C53.28 22.7 54.13 23.67 54.05 24.79C53.97 25.91 53 26.76 51.88 26.68L49.61 26.52C48.49 26.44 47.64 25.47 47.72 24.35C47.8 23.23 48.78 22.38 49.9 22.46Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M48.24 26.57L51.11 26.77C52.02 26.83 52.71 27.62 52.64 28.53C52.58 29.44 51.79 30.12 50.88 30.06L48.01 29.86C47.1 29.79 46.42 29 46.48 28.1C46.55 27.19 47.34 26.5 48.24 26.57Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M47.28 30.13L48.97 30.25C49.66 30.3 50.18 30.9 50.13 31.58C50.08 32.27 49.49 32.79 48.8 32.74L47.1 32.62C46.42 32.57 45.9 31.98 45.95 31.29C46 30.6 46.59 30.08 47.28 30.13Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M46.96 30.57L47.95 30.64C48.12 30.65 48.25 30.8 48.24 30.97L48.17 31.86C48.16 32.03 48.01 32.16 47.84 32.15L46.85 32.08C46.68 32.06 46.55 31.92 46.56 31.74L46.62 30.86C46.64 30.69 46.79 30.56 46.96 30.57Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M48.05 27.33L49.16 27.41C49.53 27.43 49.8 27.75 49.78 28.12L49.74 28.66C49.71 29.02 49.4 29.3 49.03 29.27L47.92 29.2C47.55 29.17 47.27 28.85 47.3 28.48L47.34 27.95C47.36 27.58 47.68 27.3 48.05 27.33Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M49.95 23.17L50.85 23.24C51.34 23.27 51.71 23.7 51.67 24.19L51.62 24.89C51.59 25.38 51.16 25.75 50.67 25.72L49.78 25.65C49.29 25.62 48.92 25.19 48.95 24.7L49 24C49.04 23.51 49.46 23.14 49.95 23.17Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M43.1 19.73C41.1 18.99 39.74 19.83 39.44 20.64C39.23 21.21 39.62 21.66 40.02 21.81C40.82 22.11 40.92 20.84 42.43 21.4C43.17 21.68 43.65 22.22 43.39 22.91C43.1 23.71 42.1 23.86 41.45 24.09C40.89 24.31 40.1 24.71 39.62 25.98C39.34 26.75 39.46 27.05 40.07 27.28C40.8 27.55 41.07 27.28 41.17 26.99C41.46 26.22 41.64 25.78 42.69 25.45C43.21 25.28 44.84 24.74 45.36 23.35C45.87 21.95 45 20.43 43.1 19.73Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M39.71 28.31C39.56 28.25 39.4 28.23 39.24 28.23C39.08 28.24 38.92 28.28 38.78 28.34C38.63 28.41 38.5 28.5 38.39 28.62C38.28 28.74 38.2 28.88 38.14 29.03C38.09 29.18 38.06 29.34 38.07 29.5C38.08 29.66 38.11 29.81 38.18 29.96C38.25 30.1 38.34 30.23 38.46 30.34C38.58 30.45 38.71 30.54 38.86 30.59C39.17 30.7 39.5 30.69 39.8 30.56C40.09 30.42 40.32 30.18 40.43 29.87C40.54 29.57 40.53 29.23 40.39 28.94C40.26 28.65 40.01 28.42 39.71 28.31Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                            </svg>

                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="info__presentation">
                        <div className="info__presentation--leftBlock">
                            <h5>{language[lang].slider_slide_1}</h5>
                            <a href='https://t.me/+HHsbIvllg24zZWFi'>
                                <span>
                                {language[lang].slider_sabmit}
                                </span>


                                <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                </svg>
                            </a>
                        </div>
                        <div className="info__svg">
                            <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect id="Social 05" width="64.000000" height="64.000000" fill="#FFFFFF" fill-opacity="0" />
                                <path id="Vector" d="M25.71 39.38C25.71 46.91 31.81 53.02 39.35 53.02C41.11 53.02 42.84 52.69 44.47 52.03C44.5 52.03 44.54 52.03 44.57 52.03L50.54 52.03C50.73 52.03 50.93 51.99 51.1 51.92C51.28 51.85 51.44 51.74 51.58 51.6C51.71 51.47 51.82 51.31 51.89 51.13C51.97 50.95 52.01 50.76 52.01 50.57L52.01 44.48C52.66 42.86 53 41.13 52.99 39.38C52.99 31.84 46.89 25.73 39.35 25.73C31.81 25.73 25.71 31.84 25.71 39.38Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M44.81 29.7C44.81 39.16 37.15 46.82 27.69 46.82C25.49 46.83 23.31 46.4 21.27 45.57C21.23 45.58 21.19 45.58 21.15 45.58L13.65 45.58C13.41 45.58 13.17 45.53 12.95 45.44C12.73 45.35 12.53 45.21 12.36 45.04C12.18 44.87 12.05 44.67 11.96 44.45C11.87 44.23 11.82 43.99 11.82 43.75L11.82 36.11C11 34.07 10.57 31.9 10.58 29.7C10.58 20.25 18.24 12.59 27.69 12.59C37.15 12.59 44.81 20.25 44.81 29.7Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M22.24 30.7C22.24 32.07 21.13 33.18 19.76 33.18C18.39 33.18 17.28 32.07 17.28 30.7C17.28 29.33 18.39 28.22 19.76 28.22C21.13 28.22 22.24 29.33 22.24 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M29.43 30.7C29.43 32.07 28.32 33.18 26.95 33.18C25.58 33.18 24.47 32.07 24.47 30.7C24.47 29.33 25.58 28.22 26.95 28.22C28.32 28.22 29.43 29.33 29.43 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M36.87 30.7C36.87 32.07 35.76 33.18 34.39 33.18C33.02 33.18 31.91 32.07 31.91 30.7C31.91 29.33 33.02 28.22 34.39 28.22C35.76 28.22 36.87 29.33 36.87 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M42.08 21.52C44.55 21.52 46.55 19.52 46.55 17.05C46.55 14.59 44.55 12.59 42.08 12.59C39.62 12.59 37.62 14.59 37.62 17.05C37.62 19.52 39.62 21.52 42.08 21.52Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector (Stroke)" d="M42.08 15.1C41.01 15.1 40.14 15.98 40.14 17.05C40.14 18.13 41.01 19 42.08 19C43.16 19 44.03 18.13 44.03 17.05C44.03 15.98 43.16 15.1 42.08 15.1ZM35.1 17.05C35.1 13.2 38.23 10.07 42.08 10.07C45.94 10.07 49.07 13.2 49.07 17.05C49.07 20.91 45.94 24.04 42.08 24.04C38.23 24.04 35.1 20.91 35.1 17.05Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector (Stroke) (Stroke)" d="M34.68 17.05C34.68 12.96 37.99 9.65 42.08 9.65C46.17 9.65 49.49 12.96 49.49 17.05C49.49 21.14 46.17 24.46 42.08 24.46C37.99 24.46 34.68 21.14 34.68 17.05ZM42.08 10.49C38.46 10.49 35.52 13.43 35.52 17.05C35.52 20.68 38.46 23.61 42.08 23.61C45.71 23.61 48.65 20.68 48.65 17.05C48.65 13.43 45.71 10.49 42.08 10.49ZM42.08 15.53C41.24 15.53 40.56 16.21 40.56 17.05C40.56 17.9 41.24 18.58 42.08 18.58C42.93 18.58 43.61 17.9 43.61 17.05C43.61 16.21 42.93 15.53 42.08 15.53ZM39.71 17.05C39.71 15.74 40.78 14.68 42.08 14.68C43.39 14.68 44.45 15.74 44.45 17.05C44.45 18.36 43.39 19.42 42.08 19.42C40.78 19.42 39.71 18.36 39.71 17.05Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M43.5 15.73C43.44 15.5 43.34 15.29 43.19 15.11C43.06 14.95 42.9 14.83 42.71 14.76C42.3 14.62 41.86 14.62 41.46 14.76C41.27 14.83 41.1 14.95 40.97 15.11C40.83 15.29 40.72 15.5 40.66 15.73C40.59 16.04 40.55 16.35 40.56 16.67L40.56 17.42C40.55 17.74 40.59 18.05 40.67 18.36C40.72 18.59 40.83 18.81 40.98 18.99C41.11 19.15 41.27 19.27 41.46 19.34C41.86 19.49 42.31 19.49 42.71 19.34C42.9 19.27 43.06 19.15 43.19 18.99C43.34 18.8 43.44 18.59 43.5 18.36C43.58 18.05 43.61 17.74 43.61 17.42L43.61 17.42L43.61 16.67C43.62 16.35 43.58 16.04 43.5 15.73ZM42.83 17.53C42.83 17.73 42.82 17.93 42.78 18.13C42.76 18.27 42.71 18.41 42.64 18.53C42.58 18.63 42.5 18.71 42.41 18.76C42.31 18.81 42.2 18.83 42.09 18.83C41.98 18.83 41.87 18.81 41.77 18.76C41.67 18.71 41.58 18.63 41.53 18.53C41.45 18.41 41.4 18.27 41.38 18.13C41.34 17.94 41.32 17.73 41.33 17.53L41.33 16.55C41.32 16.35 41.34 16.15 41.38 15.95C41.4 15.81 41.45 15.68 41.52 15.56C41.58 15.47 41.66 15.39 41.76 15.35C41.86 15.3 41.97 15.28 42.08 15.28C42.19 15.28 42.3 15.3 42.4 15.35C42.5 15.39 42.58 15.47 42.63 15.56C42.71 15.68 42.76 15.81 42.78 15.95C42.82 16.15 42.83 16.35 42.83 16.55L42.83 17.53L42.83 17.53Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                            </svg>


                        </div>
                    </div>
                </SwiperSlide> 
                <SwiperSlide>
                    <div className="info__presentation">
                        <div className="info__presentation--leftBlock">
                            <h5>{language[lang].slider_slide_4}</h5>
                            <a href="/media/SC%20presa.pdf" download='FI presentation.pdf'>
                                <span>
                                {language[lang].slider_sabmit}
                                </span>
                                <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                </svg>
                            </a>
                        </div>
                        <div className="info__svg">

                            <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" >

                                <path id="Vector" d="M24.56 42.23C22.01 42.11 19.12 39.06 20.39 38.25C21.66 37.44 21.51 35.74 21.05 35.51C18.19 34.39 17.49 31.88 16.88 28.68C16.26 25.47 16.61 15.28 16.61 15.28L20.2 13.47L30.12 12.89L32.94 17.56C32.94 17.56 31.9 20.35 31.82 23.14C32.49 22.6 33.67 21.86 34.56 22.39C35.13 22.73 35.47 23.27 35.62 23.86C35.81 24.64 35.67 25.53 35.25 26.25C34.96 26.75 34.63 27.25 34.16 27.63C33.5 28.17 32.58 28.48 31.16 28.23C31.22 28.99 32.7 29.02 32.7 29.8C32.7 30.53 32.68 31.65 32.7 32.08C32.94 35.55 34.83 36.75 35.79 37.91C36.76 39.06 27.11 42.34 24.56 42.23Z" fill="#FEE3CA" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M20 24.8C20 26.5 18.74 27.87 17.19 27.87C15.63 27.87 14.37 26.5 14.37 24.8C14.37 23.11 15.63 21.74 17.19 21.74C18.74 21.74 20 23.11 20 24.8Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M19.46 24.8C19.46 26.16 18.44 27.26 17.19 27.26C15.93 27.26 14.91 26.16 14.91 24.8C14.91 23.45 15.93 22.35 17.19 22.35C18.44 22.35 19.46 23.45 19.46 24.8Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M27.34 25.24C27.34 26.82 26.12 28.1 24.62 28.1C23.11 28.1 21.9 26.82 21.9 25.24C21.9 23.67 23.11 22.39 24.62 22.39C26.12 22.39 27.34 23.67 27.34 25.24Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M26.78 25.24C26.78 26.58 25.81 27.66 24.62 27.66C23.42 27.66 22.45 26.58 22.45 25.24C22.45 23.91 23.42 22.83 24.62 22.83C25.81 22.83 26.78 23.91 26.78 25.24Z" fill="#FFFCEC" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M19.87 23.86C19.87 23.86 21.27 23.33 22.19 24.34C22.19 24.51 22.05 24.8 22.05 24.8C22.05 24.8 20.81 23.83 19.87 24.57C19.71 24.26 19.62 23.93 19.87 23.86Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M27.11 24.5L31.77 22.83L31.73 23.24L27.26 25.11L27.11 24.5Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M8.16 45.05C8.06 44.77 8.02 44.47 8.04 44.18C8.07 43.88 8.16 43.59 8.31 43.33L9.5 41.23C9.5 41.23 9.66 40.4 13.16 39.91C16.66 39.42 20.03 38.54 20.39 38.25C20.39 38.25 26.57 43.57 35.68 37.78C35.68 37.78 43.11 41.97 44.81 42.13C45.81 42.22 46.59 44.18 47.06 45.79C47.14 46.05 47.16 46.33 47.12 46.61C47.09 46.88 47 47.15 46.86 47.39C46.72 47.63 46.53 47.84 46.3 48C46.08 48.16 45.82 48.28 45.55 48.34C38.03 50.01 11.9 55.04 8.16 45.05Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M34.16 27.63C33.28 29.73 32.68 30.61 31.97 30.53C31.23 30.45 31 29.26 31.05 28.21C32.53 28.5 33.49 28.18 34.16 27.63Z" fill="#FB902E" fill-opacity="0.800000" fill-rule="nonzero" />
                                <path id="Vector" d="M35.64 23.81C35.63 23.83 35.63 23.85 35.62 23.86C35.47 23.26 35.13 22.73 34.56 22.39C33.65 21.84 32.43 22.64 31.76 23.18C31.89 20.75 31.68 18 30.35 17.41C28.03 16.36 27.94 16.6 26.5 16.67C25.15 16.75 21.84 16.95 21.27 16.63C21.65 16.98 23.4 18.47 26.19 18.6C26.5 18.83 26.3 19.03 25.99 18.99C25.68 18.95 21.47 18.87 18.31 14.9C18.31 14.9 16.47 18.33 16.51 21.42C16.51 21.42 15.33 19.1 16.07 15.78C16.51 13.31 17.65 13.55 17.65 13.55C17.65 13.55 16.88 8.03 22.17 8.53C23.71 8.72 26.34 11 28.11 10.96C29.89 10.92 31.51 11.04 31.51 11.04C31.51 11.04 34.02 10.65 34.44 14.05C34.46 14.73 34.95 15.04 36.29 15.21C37.68 15.38 37.45 18.95 35.64 23.81Z" fill="#FFA552" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M48.26 53.17C51.81 53.17 54.69 50.29 54.69 46.74C54.69 43.19 51.81 40.32 48.26 40.32C44.72 40.32 41.84 43.19 41.84 46.74C41.84 50.29 44.72 53.17 48.26 53.17Z" fill="#FB902E" fill-opacity="0.800000" fill-rule="nonzero" />
                                <path id="Vector (Stroke)" d="M48.26 42.48C45.91 42.48 44 44.39 44 46.74C44 49.1 45.91 51 48.26 51C50.62 51 52.53 49.1 52.53 46.74C52.53 44.39 50.62 42.48 48.26 42.48ZM39.67 46.74C39.67 42 43.52 38.15 48.26 38.15C53.01 38.15 56.86 42 56.86 46.74C56.86 51.49 53.01 55.34 48.26 55.34C43.52 55.34 39.67 51.49 39.67 46.74Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M50.74 46.21L48.91 46.21C48.9 46.21 48.88 46.2 48.87 46.2C48.86 46.19 48.84 46.18 48.83 46.17C48.82 46.16 48.82 46.15 48.81 46.14C48.81 46.13 48.8 46.11 48.8 46.1L48.8 44.27C48.8 44.13 48.74 43.99 48.64 43.89C48.54 43.79 48.41 43.73 48.26 43.73C48.12 43.73 47.99 43.79 47.89 43.89C47.78 43.99 47.73 44.13 47.73 44.27L47.73 46.1C47.73 46.13 47.71 46.15 47.69 46.17C47.67 46.19 47.65 46.21 47.62 46.21L45.79 46.21C45.65 46.21 45.51 46.26 45.41 46.36C45.31 46.46 45.25 46.6 45.25 46.74C45.25 46.89 45.31 47.02 45.41 47.12C45.51 47.23 45.65 47.28 45.79 47.28L47.62 47.28C47.63 47.28 47.65 47.28 47.66 47.29C47.67 47.3 47.68 47.3 47.69 47.31C47.7 47.32 47.71 47.34 47.72 47.35C47.72 47.36 47.73 47.38 47.73 47.39L47.73 49.22C47.73 49.36 47.78 49.5 47.89 49.6C47.99 49.7 48.12 49.75 48.26 49.75C48.41 49.75 48.54 49.7 48.64 49.6C48.74 49.5 48.8 49.36 48.8 49.22L48.8 47.39C48.8 47.38 48.81 47.36 48.81 47.35C48.82 47.34 48.82 47.32 48.83 47.31C48.84 47.3 48.86 47.3 48.87 47.29C48.88 47.28 48.9 47.28 48.91 47.28L50.74 47.28C50.88 47.28 51.02 47.23 51.12 47.12C51.22 47.02 51.28 46.89 51.28 46.74C51.28 46.6 51.22 46.46 51.12 46.36C51.02 46.26 50.88 46.21 50.74 46.21Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                            </svg>



                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div className="info__presentation">
                        <div className="info__presentation--leftBlock">
                            <h5>{language[lang].slider_slide_2}</h5>
                            <a href='/investors'>
                                <span>
                                {language[lang].slider_sabmit}
                                </span>


                                <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                </svg>
                            </a>
                        </div>
                        <div className="info__svg">
                            <svg width="52.000000" height="49.000000" viewBox="0 0 52 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Vector" d="M50.79 5.08C50.79 5.26 50.78 5.43 50.76 5.6C50.63 7.13 49.9 8.54 48.73 9.54C47.57 10.54 46.05 11.05 44.52 10.95C42.99 10.84 41.56 10.15 40.53 9C39.51 7.86 38.97 6.36 39.04 4.82C39.11 3.29 39.77 1.84 40.89 0.79C42.02 -0.26 43.5 -0.83 45.04 -0.79C46.58 -0.76 48.04 -0.12 49.11 0.97C50.18 2.07 50.79 3.55 50.79 5.08Z" fill="#FFD0A4" />
                                <path id="Vector" d="M45.65 5.53C45.27 5.83 44.74 6.42 44.9 7.72C44.72 8.23 44.09 9.2 44.09 9.66C44.09 10.13 43.33 10.21 43.25 10.08C43.16 9.96 43.12 8.36 42.66 8.06C42.2 7.77 42.28 6.42 42.28 6.42C42.28 6.42 42.45 5.91 41.9 5.58C41.35 5.24 40.97 4.44 41.46 3.97C41.94 3.51 40.64 3.22 40.47 2.88C40.4 2.74 40.12 2.42 39.83 2.1C40.46 1.03 41.41 0.19 42.55 -0.31C42.98 0.14 43.35 0.72 43.25 1.32C43.27 1.62 44.55 2.08 43.92 2.71C43.29 3.34 42.44 4.23 43.92 4.52C45.4 4.82 46.03 5.24 45.65 5.53Z" fill="#FB902E" />
                                <path id="Vector" d="M49.02 0.88C48.7 1.54 47.91 2.08 47.46 2.08C46.96 1.53 45.86 2.29 45.76 1.41C45.7 0.89 46.29 0.09 46.79 -0.49C47.62 -0.21 48.39 0.26 49.02 0.88Z" fill="#FB902E" />
                                <path id="Vector" d="M50.76 5.6C50.64 7.05 49.97 8.41 48.9 9.4C48.71 9.21 48.54 8.99 48.6 8.82C48.73 8.44 49.24 7.64 49.15 7.13C49.07 6.66 49.5 5.24 50.76 5.6Z" fill="#FB902E" />
                                <path id="Vector" d="M41.2 34.8C41.01 35.96 40.7 37.1 40.27 38.2C37.47 45.36 29.96 49.85 22.06 48.56C17.7 47.85 13.8 45.43 11.22 41.85C8.64 38.26 7.59 33.79 8.3 29.43C8.34 29.21 8.38 28.99 8.42 28.77C10.2 20.05 18.57 14.22 27.43 15.67C30.08 16.1 32.58 17.16 34.72 18.77C35.43 19.29 36.09 19.87 36.7 20.51C38.52 22.37 39.87 24.64 40.65 27.12C41.43 29.6 41.62 32.23 41.2 34.8L41.2 34.8Z" fill="#FFB877" />
                                <path id="Vector" d="M40.27 38.2C37.09 40.24 33.34 42.13 29.21 43.69C25.01 45.28 20.87 46.36 17.09 46.92C8.19 48.25 1.2 46.76 -0.37 42.6C-1.85 38.71 1.78 33.44 8.42 28.77C8.38 28.99 8.34 29.21 8.3 29.43C8.17 30.24 8.1 31.06 8.08 31.88C4.26 34.84 2.26 37.73 3.05 39.81C3.81 41.83 7.06 42.68 11.67 42.44C16.18 42.2 21.99 40.91 28.08 38.6C33.35 36.61 37.97 34.19 41.41 31.75C46.01 28.5 48.51 25.22 47.65 22.94C46.76 20.59 42.53 19.81 36.7 20.51C36.09 19.87 35.43 19.29 34.72 18.77C42.89 17.82 49.18 19.36 50.67 23.29C52.26 27.48 47.92 33.28 40.27 38.2Z" fill="#FB902E" />
                            </svg>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="info__presentation">
                        <div className="info__presentation--leftBlock">
                            <h5>{language[lang].slider_slide_5}</h5>
                            <a href='/referals'>
                                <span>
                                {language[lang].slider_sabmit}
                                </span>


                                <svg width="12.833252" height="12.833328" viewBox="0 0 12.8333 12.8333" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                    <path id="Vector" d="M9.1 0L3.72 0C1.39 0 0 1.39 0 3.72L0 9.09C0 11.44 1.39 12.83 3.72 12.83L9.1 12.83C11.44 12.83 12.83 11.44 12.83 9.1L12.83 3.72C12.83 1.39 11.44 0 9.1 0ZM10.61 6.75L7.85 9.5C7.76 9.6 7.63 9.65 7.51 9.65C7.39 9.65 7.27 9.6 7.17 9.5C6.99 9.32 6.99 9.01 7.17 8.82L9.1 6.89L2.56 6.89C2.3 6.89 2.08 6.67 2.08 6.41C2.08 6.15 2.3 5.93 2.56 5.93L9.1 5.93L7.17 4C6.99 3.81 6.99 3.5 7.17 3.32C7.36 3.13 7.67 3.13 7.85 3.32L10.61 6.07C10.7 6.16 10.75 6.28 10.75 6.41C10.75 6.54 10.7 6.66 10.61 6.75Z" fill="#949494" fill-opacity="1.000000" fill-rule="nonzero" />
                                </svg>
                            </a>
                        </div>
                        <div className="info__svg">
                            <svg width="64.000000" height="64.000000" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect id="Social 05" width="64.000000" height="64.000000" fill="#FFFFFF" fill-opacity="0" />
                                <path id="Vector" d="M25.71 39.38C25.71 46.91 31.81 53.02 39.35 53.02C41.11 53.02 42.84 52.69 44.47 52.03C44.5 52.03 44.54 52.03 44.57 52.03L50.54 52.03C50.73 52.03 50.93 51.99 51.1 51.92C51.28 51.85 51.44 51.74 51.58 51.6C51.71 51.47 51.82 51.31 51.89 51.13C51.97 50.95 52.01 50.76 52.01 50.57L52.01 44.48C52.66 42.86 53 41.13 52.99 39.38C52.99 31.84 46.89 25.73 39.35 25.73C31.81 25.73 25.71 31.84 25.71 39.38Z" fill="#FFB877" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M44.81 29.7C44.81 39.16 37.15 46.82 27.69 46.82C25.49 46.83 23.31 46.4 21.27 45.57C21.23 45.58 21.19 45.58 21.15 45.58L13.65 45.58C13.41 45.58 13.17 45.53 12.95 45.44C12.73 45.35 12.53 45.21 12.36 45.04C12.18 44.87 12.05 44.67 11.96 44.45C11.87 44.23 11.82 43.99 11.82 43.75L11.82 36.11C11 34.07 10.57 31.9 10.58 29.7C10.58 20.25 18.24 12.59 27.69 12.59C37.15 12.59 44.81 20.25 44.81 29.7Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector" d="M22.24 30.7C22.24 32.07 21.13 33.18 19.76 33.18C18.39 33.18 17.28 32.07 17.28 30.7C17.28 29.33 18.39 28.22 19.76 28.22C21.13 28.22 22.24 29.33 22.24 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M29.43 30.7C29.43 32.07 28.32 33.18 26.95 33.18C25.58 33.18 24.47 32.07 24.47 30.7C24.47 29.33 25.58 28.22 26.95 28.22C28.32 28.22 29.43 29.33 29.43 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M36.87 30.7C36.87 32.07 35.76 33.18 34.39 33.18C33.02 33.18 31.91 32.07 31.91 30.7C31.91 29.33 33.02 28.22 34.39 28.22C35.76 28.22 36.87 29.33 36.87 30.7Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M42.08 21.52C44.55 21.52 46.55 19.52 46.55 17.05C46.55 14.59 44.55 12.59 42.08 12.59C39.62 12.59 37.62 14.59 37.62 17.05C37.62 19.52 39.62 21.52 42.08 21.52Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="nonzero" />
                                <path id="Vector (Stroke)" d="M42.08 15.1C41.01 15.1 40.14 15.98 40.14 17.05C40.14 18.13 41.01 19 42.08 19C43.16 19 44.03 18.13 44.03 17.05C44.03 15.98 43.16 15.1 42.08 15.1ZM35.1 17.05C35.1 13.2 38.23 10.07 42.08 10.07C45.94 10.07 49.07 13.2 49.07 17.05C49.07 20.91 45.94 24.04 42.08 24.04C38.23 24.04 35.1 20.91 35.1 17.05Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector (Stroke) (Stroke)" d="M34.68 17.05C34.68 12.96 37.99 9.65 42.08 9.65C46.17 9.65 49.49 12.96 49.49 17.05C49.49 21.14 46.17 24.46 42.08 24.46C37.99 24.46 34.68 21.14 34.68 17.05ZM42.08 10.49C38.46 10.49 35.52 13.43 35.52 17.05C35.52 20.68 38.46 23.61 42.08 23.61C45.71 23.61 48.65 20.68 48.65 17.05C48.65 13.43 45.71 10.49 42.08 10.49ZM42.08 15.53C41.24 15.53 40.56 16.21 40.56 17.05C40.56 17.9 41.24 18.58 42.08 18.58C42.93 18.58 43.61 17.9 43.61 17.05C43.61 16.21 42.93 15.53 42.08 15.53ZM39.71 17.05C39.71 15.74 40.78 14.68 42.08 14.68C43.39 14.68 44.45 15.74 44.45 17.05C44.45 18.36 43.39 19.42 42.08 19.42C40.78 19.42 39.71 18.36 39.71 17.05Z" fill="#FB902E" fill-opacity="1.000000" fill-rule="evenodd" />
                                <path id="Vector" d="M43.5 15.73C43.44 15.5 43.34 15.29 43.19 15.11C43.06 14.95 42.9 14.83 42.71 14.76C42.3 14.62 41.86 14.62 41.46 14.76C41.27 14.83 41.1 14.95 40.97 15.11C40.83 15.29 40.72 15.5 40.66 15.73C40.59 16.04 40.55 16.35 40.56 16.67L40.56 17.42C40.55 17.74 40.59 18.05 40.67 18.36C40.72 18.59 40.83 18.81 40.98 18.99C41.11 19.15 41.27 19.27 41.46 19.34C41.86 19.49 42.31 19.49 42.71 19.34C42.9 19.27 43.06 19.15 43.19 18.99C43.34 18.8 43.44 18.59 43.5 18.36C43.58 18.05 43.61 17.74 43.61 17.42L43.61 17.42L43.61 16.67C43.62 16.35 43.58 16.04 43.5 15.73ZM42.83 17.53C42.83 17.73 42.82 17.93 42.78 18.13C42.76 18.27 42.71 18.41 42.64 18.53C42.58 18.63 42.5 18.71 42.41 18.76C42.31 18.81 42.2 18.83 42.09 18.83C41.98 18.83 41.87 18.81 41.77 18.76C41.67 18.71 41.58 18.63 41.53 18.53C41.45 18.41 41.4 18.27 41.38 18.13C41.34 17.94 41.32 17.73 41.33 17.53L41.33 16.55C41.32 16.35 41.34 16.15 41.38 15.95C41.4 15.81 41.45 15.68 41.52 15.56C41.58 15.47 41.66 15.39 41.76 15.35C41.86 15.3 41.97 15.28 42.08 15.28C42.19 15.28 42.3 15.3 42.4 15.35C42.5 15.39 42.58 15.47 42.63 15.56C42.71 15.68 42.76 15.81 42.78 15.95C42.82 16.15 42.83 16.35 42.83 16.55L42.83 17.53L42.83 17.53Z" fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
                            </svg>


                        </div>
                    </div>
                </SwiperSlide>
               
            </Swiper>





            <Drawer
                title={language[lang].slider_title} onClose={onClose} open={open} placement='bottom' key='bottom' className='drawerCustom'
            >
                <p className='managerBlock'>
                    <h4>{language[lang].slider_title_manager_3}</h4>
                    <a href='https://t.me/manager_smart_club'>
                        <span>
                            @manager_smart_club
                        </span>
                    </a>
                </p>
                <br></br>
                <p className='managerBlock'>
                    
                    <h4>{language[lang].slider_title_manager_2}</h4>
                    <a href='https://t.me/manager_api_help'>
                        <span>
                            @manager_api_help
                        </span>
                    </a>
                </p>
                <br></br>
                <p className='managerBlock'>
                    <h4>{language[lang].slider_title_manager_1}</h4>
                    <a href='https://t.me/manager_invest_trade'>
                        <span>
                            @manager_invest_trade
                        </span>
                    </a>
                </p>
            </Drawer>
        </>
    );
}


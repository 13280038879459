import { useEffect, useState } from "react"
import axios from 'axios'

const tg = window?.Telegram?.WebApp;

export function useTelegram() {
    const [ userData, setUserData ] = useState({})

    const onClose = () => {
        tg.close()
    }

    const onToggleButton = () => {
        if(tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }
    useEffect(() => {
        async function fetchData() {
            axios.get(`https://smartclub.site/api/user/${tg?.initDataUnsafe?.user?.id}`).then((response) => {
                setUserData(response.data);
              });
            console.log(1);
            
        }
        fetchData ()
    },[])

    return {
        userData,
        onClose,
        onToggleButton,
        tg,
        first_name: tg?.initDataUnsafe?.user?.first_name,
        user: tg?.initDataUnsafe?.user,
        queryId: tg?.initDataUnsafe?.query_id,
        telegram_id: tg?.initDataUnsafe?.user?.id
    }
}